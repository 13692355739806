import React from 'react';
import { List, Toolbar, Divider, IconButton, Box, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, thirdListItems, Drawer } from "./side.jsx";

export function Sidebar({ open, toggleDrawer, changeView }) {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [2],
          py: [1],
        }}
      >
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <Box sx={{ flexGrow: 1, overflow: 'auto', py: 2 }}>
        <List component="nav">
          {mainListItems(changeView)}
        </List>
      </Box>
      <Divider />
      <List component="nav" sx={{ py: 1 }}>
        {thirdListItems()}
      </List>
    </Drawer>
  );
}
