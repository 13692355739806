import React from 'react';
import {rgba} from "polished";

export const CustomTooltip = ({active, payload, label, colors}) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip"
                 style={{backgroundColor: rgba(0, 0, 0, 0.5), borderRadius: 2, paddingInline: 10, paddingBlock: 5}}>
                <p className="label" style={{fontSize: '0.8em', lineHeight: '0.8em'}}>{`Date: ${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{
                        fontSize: '0.8em',
                        color: colors[index % colors.length],
                        lineHeight: '0.8em',
                    }}>{`${entry.dataKey}: ${entry.value}`}</p>
                ))}
            </div>
        );
    }

    return null;
};
