import axios from 'axios';
import {toast} from "react-toastify";
import moment from 'moment-timezone';

const API_URL = 'https://ads.octagon-lab.com';

// Create a reusable axios instance with common configuration
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Helper function to get the authorization header
const getAuthHeader = (token) => ({
  Authorization: `basic ${token || localStorage.getItem('oct_ads_token')}`,
});

// Generic error handler
const handleApiError = (error, message) => {
  toast.error(`${message}: ${error}`);
  return null;
};

export const GetStat = async (time_interval = 'day', project, campaignid, start, end, time_zone) => {
  try {
    const response = await api.get('/stat', {
      headers: getAuthHeader(),
      params: { 
        time_interval, 
        project, 
        campaignid, 
        start: moment(start).tz(time_zone).format(),
        end: moment(end).tz(time_zone).format(),
        time_zone 
      },
    });

    return response.data;
  } catch (error) {
    return handleApiError(error, 'Failed to get stats');
  }
};

export const CheckAuth = async (login, password) => {
  try {
    const response = await api.get('/stat', {
      headers: getAuthHeader(btoa(`${login}:${password}`)),
      params: { time_interval: 'day', project: 'cleanify' },
    });

    return response.data;
  } catch (error) {
    return handleApiError(error, 'Failed to authenticate');
  }
};

export const GetProjects = async () => {
  try {
    const response = await api.get('/projects', {
      headers: getAuthHeader(),
    });

    return response.data;
  } catch (error) {
    return handleApiError(error, 'Failed to get projects');
  }
};
