import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {CheckAuth} from "../../services/api.js";
import {useEffect} from "react";
import {Container, Typography} from "@mui/material";
import {alpha, styled} from "@mui/system";


export default function AuthView() {
    const navigate = useNavigate();

    useEffect(() => {
        const canvas = document.getElementById('gradient-wave');
        const updateCanvasSize = () => {
            canvas.height = window.innerHeight;
            canvas.width = window.innerWidth;
        };
        updateCanvasSize();
        window.addEventListener('resize', updateCanvasSize);
        return () => {
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const login = data.get('login');
        const password = data.get('password');
        try {
            const response = await CheckAuth(login, password);
            if (response) {
                // set email and pass to local storage token for basic auth
                localStorage.setItem('oct_ads_token', btoa(`${login}:${password}`));
                navigate('/dashboard');
            }
        } catch (error) {
            toast.error("Failed to login");
        }


    };

    const StyledBox = styled(Box)(({theme}) => ({
        background: alpha(theme.palette.background.paper, 0.7),
        backdropFilter: 'blur(20px)',
        borderRadius: theme.shape.borderRadius * 2,
        padding: theme.spacing(6),
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
        maxWidth: '400px',
        width: '100%',
    }));

    const StyledTextField = styled(TextField)(({theme}) => ({
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: alpha(theme.palette.common.white, 0.3),
            },
            '&:hover fieldset': {
                borderColor: alpha(theme.palette.common.white, 0.5),
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiInputLabel-root': {
            color: alpha(theme.palette.common.white, 0.7),
        },
        '& .MuiInputBase-input': {
            color: theme.palette.common.white,
        },
    }));

    return (
        <Container maxWidth={false} disableGutters sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
            padding: 2,
        }}>
            <canvas id="gradient-wave" className="gradient-wave" style={{position: 'absolute', zIndex: 0}}></canvas>
            <StyledBox>
                <Box sx={{textAlign: 'center', mb: 4}}>
                    <img src='/logo_big.png' alt="Logo" style={{width: '120px', marginBottom: '24px'}}/>
                    <Typography component="h1" variant="h4" color="white" fontWeight="bold">
                        Sign In
                    </Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit}>
                    <StyledTextField
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="Login"
                        name="login"
                        autoComplete="login"
                        autoFocus
                    />
                    <StyledTextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 4,
                            mb: 2,
                            py: 1.5,
                            background: 'linear-gradient(90deg, #667eea 0%, #764ba2 100%)',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #667eea 0%, #764ba2 100%)', // Same as non-hover state
                            },
                        }}
                    >
                        Sign In
                    </Button>
                </Box>
            </StyledBox>
        </Container>
    );
}
