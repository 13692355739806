import * as React from 'react';
import {useContext, useEffect} from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import {FilterContext} from "../../state/filters";
import moment from "moment-timezone";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";


export default function Filters() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        selectedStartDate, setSelectedStartDate,
        selectedEndDate, setSelectedEndDate,
        selectedTimezone, setTimezone,
        selectedInterval, setInterval,
        selectedProject, setProject,
        dateRange, setDateRange,
        projects
    } = useContext(FilterContext);

    let timeZones = moment.tz.names();
    timeZones.sort((a, b) => {
        let offsetA = moment.tz(a).utcOffset();
        let offsetB = moment.tz(b).utcOffset();
        return offsetA - offsetB;
    });

    const handleChange = (setter) => (event) => setter(event.target.value);
    const handleTimezoneChange = (_, newValue) => setTimezone(newValue);

    const handleDateChange = (setter) => (date) => {
        const momentDate = moment(date).tz('UTC', false);
        setter(momentDate[setter === setSelectedStartDate ? 'startOf' : 'endOf']('day').toDate());
        setDateRange('');
    };

    const calculateDates = (range) => {
        const date = new Date();

        const start = new Date();
        const end = new Date();

        switch (range) {
            case 'lastMonth':
                start.setMonth(date.getMonth() - 1);
                break;
            case 'last7Days':
                start.setDate(date.getDate() - 7);
                break;
            case 'today':
                break;
            case 'yesterday':
                start.setDate(date.getDate() - 1);
                end.setDate(date.getDate() - 1);
                break;
            default:
                break;
        }

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        setSelectedStartDate(start);
        setSelectedEndDate(end);
    };

    useEffect(() => {
        if (dateRange !== "") {
            calculateDates(dateRange);
        } // eslint-disable-next-line
    }, [dateRange]);

    const commonSelectProps = {
        displayEmpty: true,
        inputProps: {'aria-label': 'Without label'},
        sx: {
            width: isSmallScreen ? '80%' : 'auto',
            mr: 2,
            mb: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            fontSize: isSmallScreen ? '0.6rem' : '1rem',
        }
    };

    const commonDatePickerProps = {
        format: "dd.MM.yyyy",
        sx: {
            width: isSmallScreen ? '80%' : 150,
            mb: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            '& .MuiInputBase-input': {
                fontSize: isSmallScreen ? '0.6rem' : '1rem',
            },
        }
    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{justifyContent: 'flex-start', width: isSmallScreen ? '100%' : 'auto'}}>
                <Select
                    value={selectedProject}
                    onChange={handleChange(setProject)}
                    {...commonSelectProps}
                >
                    <MenuItem value="" disabled>
                        Project
                    </MenuItem>
                    <MenuItem value={''}>All</MenuItem>
                    {projects.map((project) => (
                        <MenuItem key={project} value={project}>{project}</MenuItem>
                    ))}
                </Select>
                <Select
                    value={selectedInterval}
                    onChange={handleChange(setInterval)}
                    {...commonSelectProps}
                >
                    <MenuItem value="" disabled>
                        Select Interval
                    </MenuItem>
                    <MenuItem value={'hour'}>Hour</MenuItem>
                    <MenuItem value={'day'}>Day</MenuItem>
                    <MenuItem value={'week'}>Week</MenuItem>
                </Select>
            </Box>
            <Box sx={{justifyContent: 'flex-end'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Select
                        value={dateRange}
                        onChange={handleChange(setDateRange)}
                        {...commonSelectProps}
                        sx={{...commonSelectProps.sx, width: isSmallScreen ? '80%' : 150}}
                    >
                        <MenuItem value="" disabled>
                            Select Date Range
                        </MenuItem>
                        <MenuItem value={'today'}>Today</MenuItem>
                        <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                        <MenuItem value={'last7Days'}>Last 7 Days</MenuItem>
                        <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                    </Select>
                    <DatePicker
                        label="Since"
                        value={selectedStartDate}
                        onChange={handleDateChange(setSelectedStartDate)}
                        {...commonDatePickerProps}
                        sx={{...commonDatePickerProps.sx, mr: 2}}
                    />
                    <DatePicker
                        label="Until"
                        value={selectedEndDate}
                        onChange={handleDateChange(setSelectedEndDate)}
                        {...commonDatePickerProps}
                    />
                </LocalizationProvider>
            </Box>
            <Box sx={{width: isSmallScreen ? 100 : 230}}>
                <Autocomplete
                    value={selectedTimezone}
                    onChange={handleTimezoneChange}
                    options={timeZones}
                    renderInput={(params) => <TextField {...params} label="Select Timezone"/>}
                    sx={{
                        mr: 2,
                        mb: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        '& .MuiInputBase-input': {
                            fontSize: isSmallScreen ? '0.6rem' : '1rem',
                        },
                    }}
                />
            </Box>
        </Box>
    );
}
