import {createContext, useCallback, useState} from 'react';
import {GetStat} from '../services/api';


// Create a context
export const CombinedContext = createContext();

// Create a provider
// eslint-disable-next-line react/prop-types
export const CombinedProvider = ({children}) => {
    const [stat, setStat] = useState([]);


    const refreshStat = useCallback(async (time_interval, project, campaignid, start, end, timezone) => {
        const fetchedStat = await GetStat(time_interval, project, campaignid, start, end, timezone);
        setStat(fetchedStat);
    }, []);


    return (
        <CombinedContext.Provider value={{stat, refreshStat}}>
            {children}
        </CombinedContext.Provider>
    );
};
