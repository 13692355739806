import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Typography from "@mui/material/Typography";
import {CustomTooltip} from "./tooltip";
import {CombinedContext} from "../../state/context";
import {FilterContext} from "../../state/filters";
import {processStatData} from "./processData";

const colors = ['#8884d8', '#82ca9d', '#ffc658', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function Graph() {
    const {stat} = useContext(CombinedContext);
    const {dateMap} = useContext(FilterContext);

    const [revenueData, setRevenueData] = useState([]);
    const [installData, setInstallData] = useState([]);

    useEffect(() => {
        setRevenueData(processStatData(stat, dateMap, 'revenue'));
        setInstallData(processStatData(stat, dateMap, 'installs'));
    }, [stat, dateMap]);


    return (
        <Box sx={{flexGrow: 1, padding: 2}}>
            <Box sx={{marginBottom: 2}}>
                <GraphComponent data={revenueData} title="Revenue"/>
            </Box>
            <GraphComponent data={installData} title="Installs"/>
        </Box>
    );
}

const GraphComponent = ({data, title}) => {
    return (
        <>
            <Typography variant="h6" component="div" gutterBottom>{title}</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100% - 50px)',
                backgroundColor: '#1a1a1a',
                padding: 2,
            }}>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart width={'100%'} data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        {Object.keys(data[0] || {}).filter(key => key !== 'name').map((appName, index) => (
                            <Line key={appName} type="monotone" dataKey={appName}
                                  stroke={colors[index % colors.length]} strokeWidth={2}/>
                        ))}
                        <CartesianGrid stroke="rgba(245, 245, 245, 0.1)"/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip content={<CustomTooltip colors={colors}/>}/>
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </>
    );
};
