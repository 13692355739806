import React, {createContext, useEffect, useState} from 'react';
import moment from 'moment-timezone';

// Create a new context
export const FilterContext = createContext();

const savedFilters = JSON.parse(localStorage.getItem('filters'));
export const DateProvider = ({children}) => {
    const [selectedStartDate, setSelectedStartDate] = useState(() => {
        return savedFilters?.selectedStartDate ? new Date(savedFilters.selectedStartDate) : (() => {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            date.setHours(0, 0, 0, 0);
            return date;
        })();
    });
    const [selectedEndDate, setSelectedEndDate] = useState(() => {
        return savedFilters?.selectedEndDate ? new Date(savedFilters.selectedEndDate) : (() => {
            const date = new Date();
            date.setHours(23, 59, 59, 999);
            return date;
        })();
    });

    const [selectedInterval, setInterval] = useState(() => {
        return savedFilters?.selectedInterval || 'day';
    });
    const [selectedProject, setProject] = useState(() => {
        return savedFilters?.selectedProject || 'fax';
    });
    const [selectedTimezone, setTimezone] = useState(() => {
        return savedFilters?.selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    });

    const [dateRange, setDateRange] = useState(() => {
        return savedFilters?.dateRange || 'last7Days';
    });

    const [dateMap, setDateMap] = useState(() => {
        return dataInitializer(selectedInterval, selectedStartDate, selectedEndDate, selectedTimezone);
    });

    const [projects, setProjects] = useState([]);

    // Save filters to local storage whenever they change
    useEffect(() => {
        if (selectedStartDate && selectedEndDate) {
            const filters = {
                selectedStartDate: moment(selectedStartDate).isValid() ? moment(selectedStartDate).tz(selectedTimezone).format() : null,
                selectedEndDate: moment(selectedEndDate).isValid() ? moment(selectedEndDate).tz(selectedTimezone).format() : null,
                selectedInterval,
                selectedProject,
                dateRange,
                selectedTimezone,
            };
            localStorage.setItem('filters', JSON.stringify(filters));
            setDateMap(dataInitializer(selectedInterval, selectedStartDate, selectedEndDate, selectedTimezone));
        }
    }, [selectedStartDate, selectedEndDate, selectedInterval, selectedProject, selectedTimezone, dateRange]);

    const calculateDates = (range) => {
        const now = moment().tz(selectedTimezone);
        let start = now.clone().startOf('day');
        let end = now.clone().endOf('day');

        switch (range) {
            case 'lastMonth':
                start = now.clone().subtract(1, 'month').startOf('day');
                break;
            case 'last7Days':
                start = now.clone().subtract(6, 'days').startOf('day');
                break;
            case 'yesterday':
                start = now.clone().subtract(1, 'day').startOf('day');
                end = now.clone().subtract(1, 'day').endOf('day');
                break;
            case 'today':
                // start and end are already set correctly
                break;
            default:
                break;
        }

        setSelectedStartDate(start.toDate());
        setSelectedEndDate(end.toDate());
    };

    const handleDateChange = (setter) => (date) => {
        const momentDate = moment(date).tz(selectedTimezone);
        setter(momentDate[setter === setSelectedStartDate ? 'startOf' : 'endOf']('day').toDate());
        setDateRange('');
    };

    return (
        <FilterContext.Provider value={{
            selectedStartDate,
            setSelectedStartDate,
            selectedEndDate,
            setSelectedEndDate,
            selectedTimezone,
            setTimezone,
            selectedInterval,
            setInterval,
            selectedProject,
            setProject,
            dateRange,
            setDateRange,
            dateMap,
            setDateMap,
            projects,
            setProjects,
            calculateDates,
            handleDateChange,
        }}>
            {children}
        </FilterContext.Provider>
    );
};

const dataInitializer = (selectedInterval, selectedStartDate, selectedEndDate, selectedTimezone) => {
    let data = {};
    let interval = selectedInterval || 'day';

    // Use the system's timezone if selectedTimezone is undefined
    const timezone = selectedTimezone || moment.tz.guess();

    // Ensure we have valid date objects
    let currentDate = selectedStartDate ? moment(selectedStartDate).tz(timezone) : moment().tz(timezone).startOf('day');
    let endDate = selectedEndDate ? moment(selectedEndDate).tz(timezone) : moment().tz(timezone).endOf('day');

    // If dates are invalid, set a default range (e.g., last 7 days)
    if (!currentDate.isValid() || !endDate.isValid()) {
        currentDate = moment().tz(timezone).subtract(6, 'days').startOf('day');
        endDate = moment().tz(timezone).endOf('day');
    }

    while (currentDate.isSameOrBefore(endDate)) {
        let day = currentDate.format(interval === 'hour' ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY');

        data[day] = {};

        switch (interval) {
            case 'day':
                currentDate.add(1, 'day');
                break;
            case 'week':
                currentDate.add(7, 'days');
                break;
            case 'hour':
                currentDate.add(1, 'hour');
                break;
            default:
                currentDate.add(1, 'day');
        }
    }

    return data;
}
