import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar} from "./side.jsx";
import {CombinedContext} from "../../state/context.js";
import {FilterContext} from "../../state/filters.js";
import {Sidebar} from './sidebar';
import {MainContent} from './main';


export default function Dashboard() {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    const [open, setOpen] = React.useState(false);
    const [view, setView] = React.useState('Stat');
    const initialRender = React.useRef(true);
    const {refreshStat} = React.useContext(CombinedContext);
    const {selectedStartDate, selectedEndDate} = React.useContext(FilterContext);
    const {selectedInterval} = React.useContext(FilterContext);
    const {selectedProject} = React.useContext(FilterContext);
    const {selectedTimezone} = React.useContext(FilterContext);


    const toggleDrawer = () => {
        setOpen(!open);
    };
    const changeView = (newView) => { // Add this function
        setView(newView);
    };

    React.useEffect(() => {
        if (initialRender.current) {
            refreshStat(selectedInterval, selectedProject, null, selectedStartDate, selectedEndDate, selectedTimezone);
            initialRender.current = false;
        } else {
            switch (view) {
                case 'Stat':
                    refreshStat(selectedInterval, selectedProject, null, selectedStartDate, selectedEndDate, selectedTimezone);
                    break;
                case 'Graph':
                    refreshStat(selectedInterval, selectedProject, null, selectedStartDate, selectedEndDate, selectedTimezone);
                    break;
                default:
                    break;
            }
        }
    }, [view, refreshStat, selectedStartDate, selectedEndDate, selectedInterval, selectedProject, selectedTimezone]);


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="absolute" open={isMobile ? false : open}>
                <Toolbar sx={{pr: '24px', display: 'flex', justifyContent: 'space-between'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}
                                    sx={{
                                        marginRight: '36px',
                                        ...(open && {display: 'none'}),
                                    }}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <img src="/logo_big.png" alt="Logo" style={{height: '20px'}}/>
                    </Box>
                </Toolbar>
            </AppBar>
            <Sidebar open={isMobile ? false : open} toggleDrawer={toggleDrawer} changeView={changeView}/>
            <MainContent view={view}/>
        </Box>
    );
}
