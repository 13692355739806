export const processStatData = (stat, data, field) => {
    const projects = new Set();

    stat.forEach(entry => {
        if (!entry.project) {
            return;
        }

        let day = new Date(entry.date);
        day = day.toLocaleDateString('en-GB')
        const appName = entry.project;
        const fieldValue = entry[field];

        projects.add(appName); // Add the project to the set of projects

        if (!data[day]) {
            data[day] = {};
        }

        if (!data[day][appName]) {
            data[day][appName] = 0;
        }

        data[day][appName] += fieldValue;
    });

    // Initialize each date with a default value of 0 for each project
    Object.values(data).forEach(dayData => {
        projects.forEach(project => {
            if (dayData[project] === undefined) {
                dayData[project] = 0;
            }
        });
    });

    return Object.keys(data).map(day => ({
        name: day,
        ...data[day]
    }));
}
