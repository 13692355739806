import * as React from 'react';
import {useContext} from 'react';
import {Box, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {format} from 'date-fns';
import {CombinedContext} from "../../state/context";
import {utcToZonedTime} from 'date-fns-tz';
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";


export default function Stat() {
    const theme = useTheme();
    const {stat} = useContext(CombinedContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {field: 'project', headerName: 'Project'},
        {field: 'campaign_id', headerName: 'Campaing ID', width: isMobile ? 110 : 130},
        {field: 'clicks', headerName: 'Clicks'},
        {field: 'installs', headerName: 'Installs'},
        {field: 'trial', headerName: 'Trial'},
        {field: 'purchases', headerName: 'Purch'},
        {field: 'revenue', headerName: 'Revenue'},
        {field: 'install_cr', headerName: 'Inst CR'},
        {field: 'purchase_cr', headerName: 'Purch CR'},
        {field: 'rpc', headerName: 'RPC'},
        {field: 'arpu', headerName: 'ARPU'},
        {
            field: 'date',
            headerName: 'Date',
            width: isMobile ? 150 : 180,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const utcDate = utcToZonedTime(date, 'Etc/UTC');
                return format(utcDate, 'HH:mm / dd.MM.yyyy');
            },
        },
    ];


    return (
        <Box sx={{height: 'auto', width: '100%', p: 2}}>
            <Typography variant="h5" gutterBottom>
                Statistics
            </Typography>
            <DataGrid
                columns={columns}
                autoHeight
                rows={stat.map((acc, index) => ({
                    id: index,
                    project: acc.project,
                    campaign_id: acc.campaignid,
                    clicks: acc.clicks,
                    installs: acc.installs,
                    purchases: acc.purchases,
                    trial: acc.trial ? acc.trial : 0,
                    revenue: acc.revenue ? acc.revenue + '$' : 0,
                    install_cr: (acc.installs / acc.clicks * 100) ? (acc.installs / acc.clicks * 100).toFixed() + '%' : 0,
                    purchase_cr: (acc.purchases / acc.installs * 100) ? (acc.purchases / acc.installs * 100).toFixed() + '%' : 0,
                    rpc: (acc.revenue && acc.clicks) ? (acc.revenue / acc.clicks).toFixed(2) + '$' : 0,
                    arpu: (acc.revenue / acc.installs) ? (acc.revenue / acc.installs).toFixed(2) + '$' : 0,
                    date: acc.date,
                }))}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[1],
                    borderRadius: 2,
                    '& .MuiDataGrid-cell': {
                        borderColor: theme.palette.divider,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        fontWeight: 'bold',
                    },
                    fontSize: isMobile ? '0.8rem' : '1rem',
                }}
                disableRowSelectionOnClick
                disableColumnMenu={isMobile}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                    pagination: {paginationModel: {pageSize: 10}},
                    sorting: {
                        sortModel: [{field: 'date', sort: 'desc'}],
                    },
                }}
            />
        </Box>
    );
}
