import './App.css';
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {ThemeProvider} from "@mui/material/styles";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from "@mui/material/CssBaseline";
import AuthView from "./features/auth/auth";
import Dashboard from "./features/dashboard/dashboard";
import {CombinedProvider} from "./state/context.js";
import {DateProvider} from "./state/filters.js";
import {useEffect, useContext} from "react";
import {createTheme} from "@mui/material";
import { FilterContext } from './state/filters.js';
import { GetProjects } from './services/api.js';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function CheckToken() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('oct_ads_token');

        if (!token && location.pathname === '/dashboard') {
            navigate('/auth');
        }

        if (token && location.pathname === '/auth') {
            navigate('/dashboard');
        }

        if (token && location.pathname === '/') {
            navigate('/dashboard');
        }
    }, [navigate, location]);

    return null;
}

function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/auth');
    }, [navigate]);
    return null;
}

function ProjectsFetcher() {
    const { setProjects } = useContext(FilterContext);

    useEffect(() => {
        async function fetchProjects() {
            try {
                const projectsList = await GetProjects();
                setProjects(projectsList);
            } catch (error) {
                console.error('Failed to fetch projects:', error);
            }
        }
        fetchProjects();
    }, [setProjects]);

    return null;
}

function AppRoutes() {
    return (
        <>
            <CheckToken />
            <ProjectsFetcher />
            <Routes>
                <Route path="/auth" element={<AuthView />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CombinedProvider>
                <DateProvider>
                    <ToastContainer theme="dark" />
                    <CssBaseline />
                    <Router>
                        <AppRoutes />
                    </Router>
                </DateProvider>
            </CombinedProvider>
        </ThemeProvider>
    );
}

export default App;
