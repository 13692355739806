import React from 'react';
import { Box, Toolbar, Container, Grid, Paper } from '@mui/material';
import Stat from "../stat/stat.js";
import Graph from "../graph/graph";
import Filters from "../common/filters";

export function MainContent({ view }) {
  return (
    <Box component="main" sx={{flexGrow: 1, height: '100vh', overflow: 'auto'}}>
      <Toolbar/>
      <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
        <Filters/>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{p: 1, minHeight: '400px'}}>
              {view === 'Stat' && <Stat/>}
              {view === 'Graph' && <Graph/>}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

